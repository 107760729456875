import React, { FC, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "gatsby";
import { Container } from "@mui/material";
import { Grid } from "@mui/system";
import ContactList from "./ContactListFooter";
import { FooterHeightSetContext } from "../../../context/footerHeightContext";
import useDimensions from "react-cool-dimensions";
import { ResizeObserver } from "@juggle/resize-observer";
import Designers from "./Designers";

const PREFIX = "Footer";

const classes = {
  footer: `${PREFIX}-footer`,
  innerContainer: `${PREFIX}-innerContainer`,
  edgeContainer: `${PREFIX}-edgeContainer`,
  homeLink: `${PREFIX}-homeLink`,
  logo: `${PREFIX}-logo`,
  noEdgeContainer: `${PREFIX}-noEdgeContainer`,
  designersWrapper: `${PREFIX}-designersWrapper`,
  designers: `${PREFIX}-designers`,
};

const Root = styled("footer")(({ theme }) => ({
  [`&.${classes.footer}`]: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    background: "rgba(236, 201, 77, 0.69)",
    padding: 12,
    paddingLeft: 20,
    paddingRight: 20,
    opacity: "100%",
    "@media (max-width: 360px)": {
      paddingLeft: 12,
      paddingRight: 12,
    },
    "& img": {
      display: "block",
    },
  },

  [`& .${classes.innerContainer}`]: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.edgeContainer}`]: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.homeLink}`]: {
    display: "inline-block",
  },

  [`& .${classes.logo}`]: {
    width: 85,
    height: "auto",
    [theme.breakpoints.up("md")]: {
      width: 114,
    },
  },

  [`& .${classes.noEdgeContainer}`]: {
    flexGrow: 1,
  },

  [`& .${classes.designersWrapper}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.designers}`]: {
    fontSize: "12px !important",
    // color: `${theme.palette.common.white} !important`,
    "& a": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

interface FooterProps {
  contactInfo: ContactInfo;
  logoOld: string;
}

const Footer: FC<FooterProps> = ({ contactInfo, logoOld }) => {
  const [width, setWidth] = React.useState(0);
  const { observe } = useDimensions({
    useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    polyfill: ResizeObserver, // Use polyfill to make this feature works on more browsers
    onResize: ({ height, width, observe, unobserve }) => {
      setFooterHeight(Math.round(height) || 0);
      setWidth(Math.round(width) || 0);
      unobserve();
      observe();
    },
  });

  const matchesDesktop = width > 900;
  const setFooterHeight = useContext(FooterHeightSetContext);

  return (
    <Root ref={observe} className={classes.footer}>
      <Container maxWidth="md" disableGutters>
        <div className={classes.innerContainer}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            spacing={6}
          >
            <Grid className={classes.noEdgeContainer}>
              <Grid container>
                <Grid size={{ xs: 9 }} style={{ flexGrow: 1 }}>
                  <ContactList
                    contactInfo={contactInfo}
                    matchesDesktop={matchesDesktop}
                  />
                </Grid>
                {matchesDesktop && (
                  <Grid>
                    <Link to="/" className={classes.homeLink}>
                      <img
                        loading="lazy"
                        src={logoOld}
                        className={classes.logo}
                        alt="Farm equipment logo"
                        width={85}
                        height={45}
                      />
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid size={{ xs: 3 }} className={classes.edgeContainer}>
              {!matchesDesktop ? (
                <Link to="/" className={classes.homeLink}>
                  <img
                    loading="lazy"
                    src={logoOld}
                    className={classes.logo}
                    alt="Farm equipment logo"
                    width={85}
                    height={45}
                  />
                </Link>
              ) : (
                <Designers />
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      {!matchesDesktop && (
        <div className={classes.designersWrapper}>
          <Designers />
        </div>
      )}
    </Root>
  );
};

export default Footer;
