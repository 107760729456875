import React, { Dispatch, FC, SetStateAction, useState } from 'react'

const FooterHeightStateContext = React.createContext(0)
const FooterHeightSetContext = React.createContext<Dispatch<SetStateAction<number>>>(() => {})

const FooterHeightProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [footerHeight, setFooterHeight] = useState(0)

  return (
    <FooterHeightStateContext.Provider value={footerHeight}>
      <FooterHeightSetContext.Provider value={setFooterHeight}>
        {children}
      </FooterHeightSetContext.Provider>
    </FooterHeightStateContext.Provider>
  )
}
export {FooterHeightProvider, FooterHeightStateContext, FooterHeightSetContext }