import React, { useEffect, useState, useContext, useMemo, FC } from "react";
import {
  HeaderHeightSetContext,
  HeaderHeightStateContext,
} from "../../../context/headerHeightContext";
import { Link, useStaticQuery, graphql } from "gatsby";
import { WindowLocation } from "@reach/router";
import { useMediaQuery, Container } from "@mui/material";
import { Grid } from "@mui/system";
import ContactList from "./ContactListHeader";
import MainNavigation from "./MainNavigation";
import clsx from "clsx";
import useDimensions from "react-cool-dimensions";
import { ResizeObserver } from "@juggle/resize-observer";

import { styled } from "@mui/system";

const HeaderStyled = styled("header")(({ theme }) => {
  return {
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    background: theme.palette.primary.dark,
    padding: 30,
    paddingTop: 44,
    "@media (max-width: 800px)": {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 12,
      paddingBottom: 12,
    },
    "& img": {
      display: "block",
    },
  };
});

const InnerContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DesktopNavigation = styled(Grid)({
  "@media (max-width:959px)": {
    display: "none",
  },
});

const MobileNavigation = styled(Grid)({
  "@media (min-width:960px)": {
    display: "none",
  },
});

const MobileContactList = styled("div")({
  "@media (min-width:568px)": {
    display: "none",
  },
});

const LogoContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    flexShrink: 0,
  },
}));

const HomeLink = styled(Link)(({ theme }) => ({
  display: "block",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      "& img:first-of-type": {
        opacity: 1,
        transform: "translateY(-50%) scale(1) rotate(360deg)",
      },
      "& img:last-of-type": {
        opacity: 0,
      },
    },
  },
}));

const LogoOld = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: "50%",
  transform: "translateY(-50%)",
  left: 0,
  display: "block",
  opacity: 1,
  [theme.breakpoints.up("md")]: {
    transition: "all 0.6s",
    opacity: 0,
    transform: "translateY(-50%) scale(0)",
  },
}));

const LogoNew = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: "50%",
  transform: "translateY(-50%)",
  left: 0,
  opacity: 0,
  [theme.breakpoints.up("md")]: {
    transition: "all 0.6s",
    opacity: 1,
    transform: "translateY(-50%) scale(1.4)",
  },
}));

interface HeaderProps {
  contactInfo: {
    email: string;
    phone: string;
    phoneText: string;
    workingTime: string;
    facebookLink: string;
  };
  logoOld: string;
  location: WindowLocation;
  scrolled: boolean;
  setScrolled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: FC<HeaderProps> = ({
  contactInfo,
  logoOld,
  location,
  scrolled,
  setScrolled,
}) => {
  const matchesPortraitMode = useMediaQuery("(min-width:568px)");
  const matchesDesktop = useMediaQuery("(min-width:960px)");

  const { observe } = useDimensions({
    useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    polyfill: ResizeObserver, // Use polyfill to make this feature works on more browsers
    onResize: ({ height, observe, unobserve }) => {
      if (headerHeight > height && scrolled) return;
      setHeaderHeight(Math.round(height) || 0);
      unobserve();
      observe();
    },
  });

  const logoWidth = useMemo(
    () => (scrolled && !matchesDesktop ? 85 : 114),
    [scrolled, matchesDesktop]
  );
  const logoHeight = useMemo(
    () => (scrolled && !matchesDesktop ? 45 : 61),
    [scrolled, matchesDesktop]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const setHeaderHeight = useContext(HeaderHeightSetContext);
  const headerHeight = useContext(HeaderHeightStateContext);

  const { logoMain } = useStaticQuery(graphql`
    query MainLogoQuery {
      logoMain: file(relativePath: { eq: "logo-main.svg" }) {
        publicURL
      }
    }
  `);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderStyled
      ref={observe}
      sx={{
        position: scrolled ? "fixed !important" : "static !important",
        paddingTop: scrolled ? 0 : "44px",
        paddingBottom: scrolled ? 0 : "30px",
        "@media (max-width: 360px)": {
          paddingLeft: scrolled ? "12px" : "20px",
          paddingRight: scrolled ? "12px" : "20px",
        },
        opacity: scrolled && !Boolean(anchorEl) ? 0.8 : 1,
        transition: "opacity 0.3s",
      }}
      className={clsx(scrolled && "mui-fixed")}
    >
      <Container maxWidth="md" disableGutters>
        <InnerContainer>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            spacing={matchesDesktop ? 6 : 2}
          >
            <LogoContainer size={{ md: 2 }}>
              <HomeLink to="/" style={{ width: logoWidth, height: logoHeight }}>
                <LogoOld
                  loading="lazy"
                  src={logoOld}
                  alt="farmeqplus logo"
                  width={85}
                  height={45}
                  style={{
                    width: logoWidth,
                    height: "auto",
                  }}
                />
                <LogoNew
                  loading="lazy"
                  src={logoMain.publicURL}
                  alt=""
                  width={85}
                  height={45}
                  style={{
                    width: logoWidth,
                    height: "auto",
                  }}
                />
              </HomeLink>
            </LogoContainer>
            <Grid size={{ md: 10 }} sx={{ flexGrow: 1 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid
                  size={{ xs: matchesDesktop ? 12 : false }}
                  sx={{ flexGrow: 1 }}
                >
                  {(scrolled || matchesPortraitMode) && (
                    <ContactList
                      contactInfo={contactInfo}
                      variant="horizontal"
                    />
                  )}
                </Grid>
                <MobileNavigation>
                  <MainNavigation
                    location={location}
                    variant="menu"
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={handleClick}
                  />
                </MobileNavigation>
                <DesktopNavigation size={{ xs: 12 }}>
                  <MainNavigation
                    location={location}
                    variant="list"
                    anchorEl={anchorEl}
                    onClose={() => {}}
                    onClick={() => {}}
                  />
                </DesktopNavigation>
              </Grid>
            </Grid>
          </Grid>
          {!scrolled && (
            <MobileContactList>
              <ContactList contactInfo={contactInfo} variant="vertical" />
            </MobileContactList>
          )}
        </InnerContainer>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
