import React, { FC, useContext } from "react";
import { styled } from "@mui/material/styles";
import Link from "../../Link";
import { Badge, IconButton } from "@mui/material";
import { MyPriceListStateContext } from "../../../context/myPriceListContext";
import clsx from "clsx";
import Menu from "../../../../assets/icons/hamburger-menu.svg";
import MobileMenu from "./MobileMenu";
import {
  GRAIN_CONVEYORS,
  GRAIN_ELEVATORS,
  INCLINE_CONVEYORS,
} from "../../../const";

const PREFIX = "MainNavigation";

const classes = {
  ul: `${PREFIX}-ul`,
  li: `${PREFIX}-li`,
  link: `${PREFIX}-link`,
  activeLink: `${PREFIX}-activeLink`,
  menuIcon: `${PREFIX}-menuIcon`,
  menuButtonImage: `${PREFIX}-menuButtonImage`,
  badge: `${PREFIX}-badge`,
  iconButtonBadge: `${PREFIX}-iconButtonBadge`,
};

const Root = styled("ul")(({ theme }) => ({
  //list view
  [`&.${classes.ul}`]: {
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    listStyle: "none",
  },

  [`& .${classes.li}`]: {
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.link}`]: {
    display: "block",
    boxSizing: "border-box",
    color: theme.palette.common.white,
    textDecoration: "none",
    textTransform: "uppercase",
    fontFamily: "Krub",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    border: "1px solid transparent",
    transition: "all 0.03s",
    padding: theme.spacing(1 / 4, 1 / 2),
    "&:hover": {
      border: "1px solid #E7B607",
    },
  },

  [`& .${classes.activeLink}`]: {
    background: "#E7B607A6",
  },

  [`& .${classes.menuIcon}`]: {
    height: "42px !important",
    width: "42px !important",
  },

  [`& .${classes.menuButtonImage}`]: {
    width: "100%",
  },

  [`& .${classes.badge}`]: {
    backgroundColor: "#EC5E0F",
  },

  [`& .${classes.iconButtonBadge}`]: {
    top: theme.spacing(2.5),
  },
}));

const navigationMenu = [
  {
    caption: GRAIN_CONVEYORS,
    link: "/new-conveyors",
  },
  {
    caption: GRAIN_ELEVATORS,
    link: "/new-elevators",
  },
  {
    caption: INCLINE_CONVEYORS,
    link: "/incline-conveyors",
  },
  // {
  //   caption: GRAIN_AUGERS,
  //   link: '/new-augers',
  // },
  // {
  //   caption: 'Used equipment',
  //   link: 'https://nasseq.net/catalog',
  // },
  {
    caption: "Contact",
    link: "/contact",
  },
  {
    caption: "All price lists",
    link: "/all-price-lists",
  },
  {
    caption: "My price list",
    link: "/my-price-list",
  },
];

const normalizePath = (path: string) => path.replace(/\/+$/, "");

interface MainNavigationProps {
  location: Location;
  variant: "menu" | "list";
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MainNavigation: FC<MainNavigationProps> = ({
  location,
  variant,
  anchorEl,
  onClose,
  onClick,
}) => {
  const myPriceList = useContext(MyPriceListStateContext);
  const open = Boolean(anchorEl);

  if (variant === "menu") {
    return (
      <Badge
        overlap="rectangular"
        variant="dot"
        showZero={true}
        invisible={!myPriceList.length}
        // anchorOrigin={{ vertical: 'top', horizontal: 'left'}}
        classes={{ badge: clsx(classes.badge, classes.iconButtonBadge) }}
      >
        <IconButton onClick={onClick} edge="end" size="large">
          <Menu
            alt="menu button"
            className={classes.menuButtonImage}
            width={42}
            height={42}
          />
        </IconButton>
        <MobileMenu
          anchorEl={anchorEl}
          onClose={onClose}
          open={open}
          navigationMenu={navigationMenu}
          path={location.pathname}
          myPriceList={myPriceList}
        />
      </Badge>
    );
  }

  return (
    <Root className={classes.ul}>
      {navigationMenu.map((menuItem) => {
        return (
          <li key={menuItem.link} className={classes.li}>
            <Badge
              overlap="rectangular"
              badgeContent={String(myPriceList.length)}
              invisible={menuItem.link !== "/my-price-list"}
              classes={{ badge: classes.badge }}
            >
              <Link
                to={menuItem.link}
                className={clsx(classes.link, {
                  [classes.activeLink]:
                    normalizePath(location.pathname) ===
                    normalizePath(menuItem.link),
                })}
              >
                {menuItem.caption}
              </Link>
            </Badge>
          </li>
        );
      })}
    </Root>
  );
};

export default MainNavigation;
