import React, { ReactNode } from "react";
import Providers from "../../components/TopLayout/TopLayout";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { Theme } from "@mui/material/styles";

export default function TopLayout({
  children,
  theme,
}: {
  children: ReactNode;
  theme: Theme;
}) {
  return (
    <Providers>
      <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
    </Providers>
  );
}
