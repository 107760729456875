import React, { FC, ReactNode } from "react";
import { HeaderHeightProvider } from "../../context/headerHeightContext";
import { FooterHeightProvider } from "../../context/footerHeightContext";
import { MyPriceListProvider } from "../../context/myPriceListContext";
import { SeenProductsProvider } from "../../context/seenProductsContext";

const TopLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <HeaderHeightProvider>
      <FooterHeightProvider>
        <MyPriceListProvider>
          <SeenProductsProvider>{children}</SeenProductsProvider>
        </MyPriceListProvider>
      </FooterHeightProvider>
    </HeaderHeightProvider>
  );
};

export default TopLayout;
