import React, { FC, ReactNode, useState } from 'react'
import { ParentProduct, ProductCategory } from '../types';

interface SeenProduct {
  id: string;
  slug: string;
  productCategories: {
    nodes: ProductCategory[]
  };
  image: {
    id: string;
    altText?: string;
    localFile: {
      publicURL: string;
    }
  },
  parentProduct: ParentProduct;
}

const SeenProductsStateContext = React.createContext<SeenProduct[]>([])
const SeenProductsSetContext = React.createContext<React.Dispatch<React.SetStateAction<SeenProduct[]>>>(() => {})

const SeenProductsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [seenProducts, setSeenProducts] = useState<SeenProduct[]>([])

  return (
    <SeenProductsStateContext.Provider value={seenProducts}>
      <SeenProductsSetContext.Provider value={setSeenProducts}>
        {children}
      </SeenProductsSetContext.Provider>
    </SeenProductsStateContext.Provider>
  )
}
export { SeenProductsProvider, SeenProductsStateContext, SeenProductsSetContext }