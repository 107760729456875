import React, { FC, ReactNode, useState } from 'react'

const HeaderHeightStateContext = React.createContext(0)
const HeaderHeightSetContext = React.createContext<React.Dispatch<React.SetStateAction<number>>>(() => {})

const HeaderHeightProvider: FC<{ children: ReactNode }> = ({children}) => {
  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <HeaderHeightStateContext.Provider value={headerHeight}>
      <HeaderHeightSetContext.Provider value={setHeaderHeight}>
        {children}
      </HeaderHeightSetContext.Provider>
    </HeaderHeightStateContext.Provider>
  )
}
export {HeaderHeightProvider, HeaderHeightStateContext, HeaderHeightSetContext }