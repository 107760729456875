import { MyPriceListItem } from "@src/types";
import React, { useState, useEffect, ReactNode, FC } from "react";

const MyPriceListStateContext = React.createContext<MyPriceListItem[]>([]);
const MyPriceListSetContext = React.createContext<
  React.Dispatch<React.SetStateAction<MyPriceListItem[]>>
>(() => {});

const MyPriceListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [myPriceList, setMyPriceList] = useState<MyPriceListItem[]>([]);

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        const localData = localStorage.getItem("myPriceList");
        setMyPriceList(localData ? JSON.parse(localData) : []);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        localStorage.setItem("myPriceList", JSON.stringify(myPriceList));
      }
    } catch (error) {}
  }, [myPriceList]);

  return (
    <MyPriceListStateContext.Provider value={myPriceList}>
      <MyPriceListSetContext.Provider value={setMyPriceList}>
        {children}
      </MyPriceListSetContext.Provider>
    </MyPriceListStateContext.Provider>
  );
};

export { MyPriceListProvider, MyPriceListStateContext, MyPriceListSetContext };
